import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Button, Columns, Container, Form, Heading, Icon, Section } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { useSession, useSessionRequest } from '../stores/session'
import { RequestMessage } from '../stores/utils'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ChooseNewPasswordPage = () => {
  const location = useLocation()
  const [formValues, setFormValues] = React.useState({
    password: '',
    passwordVerification: '',
    token: queryString.parse(location.search).token
  })

  const [request] = useSessionRequest({
    key: 'post /users/reset-password'
  })

  const [state, actions] = useSession()

  const submitForm = React.useCallback((e) => {
    e.preventDefault()
    actions.resetPassword(formValues)
  }, [formValues])
  const { t } = useTranslation()
  return (
    <Layout>
      
      <Section size='medium'>
        <Container>
          <Columns centered gap={8}>
            <Columns.Column size={6}>
              <Heading size={5}>
                {t('choose-new-password:title')}
              </Heading>
              <RequestMessage
                request={ request }
                messages={{
                  success: <span>
                    {t('choose-new-password:confirmation')}
                  </span>
                }}
              />
              {
                (!request || request.status !== 'success') &&
                  <form onSubmit={ submitForm }>
                    <Form.Field>
                      <Form.Label>
                        {t('forms:contact.password')}
                      </Form.Label>
                      <Form.Control>
                        <Icon align='left'>
                          <FontAwesomeIcon icon={faShieldAlt} />
                        </Icon>
                        <Form.Input
                          type='password'
                          value={formValues.password}
                          mb={3}
                          onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                        />
                      </Form.Control>
                      <Form.Label>
                        {t('forms:contact.password_verification')}
                      </Form.Label>
                      <Form.Control>
                        <Icon align='left'>
                          <FontAwesomeIcon icon={faShieldAlt} />
                        </Icon>
                        <Form.Input
                          type='password'
                          value={formValues.passwordVerification}
                          mb={3}
                          onChange={(e) => setFormValues({ ...formValues, passwordVerification: e.target.value })}
                        />
                      </Form.Control>
                      <Form.Help>
                        {t('forms:validations.password.title')}<br />
                        <ul>
                          <li>
                            ✓ {t('forms:validations.password.length')}
                          </li>
                          <li>
                            ✓ {t('forms:validations.password.case')}
                          </li>
                          <li>
                            ✓ {t('forms:validations.password.character')}
                          </li>
                        </ul>
                      </Form.Help>
                    </Form.Field>
                    <Form.Field mt={5}>
                      <Form.Control>
                        <Button
                          type='submit'
                          color='primary'
                        >
                          {t('choose-new-password:submit')}
                        </Button>
                      </Form.Control>
                      <Form.Help>

                      </Form.Help>
                    </Form.Field>
                  </form>
              }

            </Columns.Column>
          </Columns>
        </Container>
      </Section >
    </Layout >
  )
}

export default ChooseNewPasswordPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`