import React from 'react'
import { Columns, Notification } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons'

export const ResourceStatusHandler = ({ request, statuses, children }) => {
  if (request && request.status === 'success') {
    return children
  }
  if (request && request.status === 'inProgress') {
    if (statuses && statuses.inProgress) return statuses.inProgress
    return 'Chargement...'
  }
  if (!request || request.status === 'error') {
    if (statuses && statuses.error) return statuses.error
    return 'Erreur de chargement.'
  }
}

export const RequestMessage = ({ request, messages = {}, disabled = [] }) => {
  const notificationColor = React.useMemo(() => {
    if (!request)
      return
    if (request.status === 'error')
      return 'danger'
    else if (request.status === 'inProgress')
      return 'info'
    return request.status
  }, [request])

  const icon = React.useMemo(() => {
    if (!request)
      return
    switch (request.status) {
    case 'error':
      return faTimes
    case 'inProgress':
      return faSpinner
    case 'success':
      return faCheck
    }
  }, [request])

  if (!request || disabled.includes(request.status))
    return <></>

  return (
    <Notification color={notificationColor}>
      <Columns vCentered>
        <Columns.Column size={2} textAlign='center'>
          <FontAwesomeIcon icon={icon} size='2x'/>
        </Columns.Column>
        <Columns.Column size={10}>
          { messages[request.status] || request.message }
        </Columns.Column>
      </Columns>
    </Notification>
  )
}